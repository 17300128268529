import { useCallback, useMemo } from "react";

import { FlagId } from "../constants/flags";
import { GrowthbookIntegration } from "./growthbook";
import flagsmith from "flagsmith";
import useRemoteConfig from "../custom-hooks/useRemoteConfig";

export const FLAGSMITH = "flagsmith";
export const GROWTHBOOK = "growthbook";
export type FlagResult = { enabled: boolean; value?: any };

export function useFlagProvider() {
  const appConfig = useRemoteConfig();
  return useMemo(() => {
    if (appConfig.isFetched) {
      const prvdr = appConfig.generalSettings?.FLAGS_PROVIDER;
      const override = getFlagProviderLocalOverride();
      return override || prvdr || FLAGSMITH;
    }
    if (appConfig.isError) {
      return FLAGSMITH;
    }
    return null;
  }, [
    appConfig.isFetched,
    appConfig.isError,
    appConfig.generalSettings?.FLAGS_PROVIDER,
  ]);
}

export function getFlagProviderLocalOverride() {
  const params = new URLSearchParams(window.location.search);
  const flagProvider = params.get("fp");

  if (flagProvider === "gb") {
    localStorage.setItem("flagProvider", GROWTHBOOK);
    return GROWTHBOOK;
  }

  if (flagProvider === "fs") {
    localStorage.setItem("flagProvider", FLAGSMITH);
    return FLAGSMITH;
  }

  // handle playwright tests
  if (window.localStorage.getItem("testFlagsProvider")) {
    localStorage.setItem("flagProvider", "playwright-tests");
  }

  return localStorage.getItem("flagProvider") || null;
}

export function useSetTrait() {
  const prvdr = useFlagProvider();
  return useCallback(
    (key: string, value: string | number | boolean | null) => {
      if (prvdr === FLAGSMITH) flagsmith.setTrait(key, value);
      if (prvdr === GROWTHBOOK) console.warn("setTrait not available");
    },
    [prvdr],
  );
}

export function useAllFlags() {
  const prvdr = useFlagProvider();
  return useMemo(() => {
    if (prvdr === FLAGSMITH) return flagsmith.getAllFlags();
    if (prvdr === GROWTHBOOK) return GrowthbookIntegration.getAllFlags();
    return {};
  }, [prvdr]);
}

export function useFlags(ids: readonly FlagId[]) {
  const flags = useAllFlags();
  return useMemo(() => {
    return ids.reduce(
      (acc, id) => {
        acc[id] = flags[id] || { enabled: false, value: null };
        return acc;
      },
      {} as Record<string, FlagResult>,
    );
  }, [flags, ids]);
}
