import { ITraits } from "flagsmith/types";
import { flagsChangedEvent } from "../utils/analytics.ts";
import flagsmith from "flagsmith";
import { logErr } from "../utils/logs.ts";
import project from "../project.ts";

export async function initFlagsmith(analyticsUuid: string, traits: ITraits) {
  // set the flags state to loading
  // this is only used for the /account area which needs to wait for the flags
  window.localStorage.setItem("flagsReady", "false");
  return flagsmith.init({
    environmentID: project.flagsmithKey,
    api: "https://api.flagsmith.com/api/v1/",
    cacheFlags: true,
    enableAnalytics: true,
    identity: `${project.customEnv}_${analyticsUuid}`,
    traits,
    preventFetch: project.testsRunning === "true",
    onChange: (_, params) => {
      // set the flags state to done if isFromServer is true
      // this is only used for the /account area which needs to wait for the flags
      if (params.isFromServer) {
        window.localStorage.setItem("flagsReady", "true");
      }
      /*
          Flags are ready when we have received a flag update after identifying.
          Expected events are:
              onChange (anonymous flags)
              Identify occurs
              onChange (users flags)
      */
      const { flagsChanged } = params;
      const allFlags = flagsmith.getAllFlags();

      // flags changed AND LOADED, update user props and send Flags Changed event
      if (flagsChanged && allFlags["flags-loaded"]?.enabled) {
        flagsChangedEvent(analyticsUuid, allFlags);
      }
    },
    onError: (err) => {
      logErr("Flagsmith error", err);
    },
  });
}
