import { Attributes, GrowthBook } from "@growthbook/growthbook-react";

import { FlagResult } from "./flags";
import { flagIds } from "../constants/flags";
import project from "../project";
import { trackAnalyticsServer } from "../utils/analytics";

const gbSetupData = {
  apiHost: "https://cdn.growthbook.io",
  clientKey: project.growthbookClientKey,
  decryptionKey:
    project.growthbookDecryptionKey?.length > 1
      ? project.growthbookDecryptionKey
      : undefined,
  enableDevMode: project.customEnv !== "production",
  subscribeToChanges: true,
};

export const growthbook = new GrowthBook(gbSetupData);

/* Attributes = Record<string, any> */
async function initGrowthbook(analyticsUuid: string, attributes: Attributes) {
  // set the flags state to loading
  // this is only used for the /account area which needs to wait for the flags
  window.localStorage.setItem("flagsReady", "false");
  growthbook.setTrackingCallback((experiment, result) => {
    // required for A/B testing - called every time a user is put into an experiment
    trackAnalyticsServer(analyticsUuid, "GrowthBook Experiment Tracked", {
      experimentId: experiment.key,
      variationId: result.key,
      variationValue: result.value,
    });
  });

  growthbook.getFeatures();

  await growthbook.setAttributes({
    id: analyticsUuid,
    ...attributes,
  });
  await growthbook.init({
    streaming: true,
    timeout: 3000,
  });

  // set the flags state to loading
  // this is only used for the /account area which needs to wait for the flags
  window.localStorage.setItem("flagsReady", "true");
}

function getAllFlags(): Record<string, FlagResult> {
  const output: Record<string, FlagResult> = {};

  const features = growthbook.getFeatures();
  if (features) {
    for (const flagName in features) {
      const flag = growthbook.evalFeature(flagName);
      output[flagName] = { enabled: flag.on, value: flag.value };
    }
  }

  // Growthbook does not send flags that are not enabled,
  // therefore we need to add them manually
  const knownFlags = flagIds;
  if (knownFlags) {
    for (const flagName of knownFlags) {
      if (!output[flagName]) {
        output[flagName] = { enabled: false, value: null };
      }
    }
  }

  return output;
}

export const GrowthbookIntegration = {
  initGrowthbook,
  getAllFlags,
};
