import { GROWTHBOOK, useFlagProvider } from "../services/flags";

import { GrowthbookIntegration } from "../services/growthbook";
import { flagsChangedEvent } from "../utils/analytics";
import { selectAnalyticsUuid } from "../selectors/analytics";
import { useEffect } from "react";
import { useGrowthBook } from "@growthbook/growthbook-react";
import { useSelector } from "react-redux";

export default function useGrowthbookFlagsChanged() {
  const prvdr = useFlagProvider();
  // local storage of flags used to confirm growthbook flags changed
  window.localStorage.removeItem("gbF");
  const gb = useGrowthBook();
  const analyticsUuid = useSelector(selectAnalyticsUuid);

  function handleFeatureChanges() {
    if (prvdr !== GROWTHBOOK) return;
    const allFlags = GrowthbookIntegration.getAllFlags();
    const latestHash = window.localStorage.getItem("gbF");
    const gbF = latestHash ? JSON.parse(latestHash) : null;
    if (JSON.stringify(gbF) !== JSON.stringify(allFlags)) {
      // flags have changed!!!!1!
      window.localStorage.setItem("gbF", JSON.stringify(allFlags));
      flagsChangedEvent(analyticsUuid, allFlags, "growthbook");
    }
  }

  useEffect(() => {
    const unsubscribe = gb.subscribe(() => {
      handleFeatureChanges();
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gb]);
}
