import "react-toastify/dist/ReactToastify.css";
import "@egjs/flicking/dist/flicking.css";
import "./index.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import store, { persistor } from "./store";

import App from "./App";
import { FlagsmithProvider } from "flagsmith/react";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import { createRoot } from "react-dom/client";
import { e2eCleanup } from "./services/Api";
import flagsmith from "flagsmith";
import { growthbook } from "./services/growthbook";
import { initAnalytics } from "./utils/analytics";
import { initSentry } from "./utils/logs";
import { reportWebVitals } from "./reportWebVitals";
import theme from "./components/styled/theme";

(async () => {
  if (window.location.pathname === "/e2e/setup") {
    const paramsString = window.location.search;
    const searchParams = new URLSearchParams(paramsString);
    const paramsMap = Object.fromEntries(searchParams);
    await e2eCleanup(paramsMap.email);
    window.localStorage.setItem("e2eTestUser", "true");
    window.location.href = paramsMap.startUrl;
    return;
  }

  const ErrorBoundary = await initSentry();

  initAnalytics();

  const container = document.getElementById("root")!;
  const root = createRoot(container);

  const reactQueryClient = new QueryClient();

  root.render(
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <FlagsmithProvider flagsmith={flagsmith}>
            <GrowthBookProvider growthbook={growthbook}>
              <QueryClientProvider client={reactQueryClient}>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </QueryClientProvider>
            </GrowthBookProvider>
          </FlagsmithProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </PersistGate>
      </Provider>
    </ErrorBoundary>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
