import { AppRouter } from "./routers/AppRouter";
import ExperimentsProvider from "./checkout/components/ExperimentsProvider/ExperimentsProvider.tsx";
import LoadingRadial from "./components/base/LoadingRadial.tsx";
import QuizStateProvider from "./adhdQuiz/QuizStateProvider/QuizStateProvider.tsx";
import { RouterProvider } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { useEffect } from "react";
import useFirstQuizLoad from "./custom-hooks/useFirstQuizLoad.ts";
import useRemoteConfig from "./custom-hooks/useRemoteConfig";
import useUserData from "./custom-hooks/useUserData";
import useUserEmailFromParam from "./custom-hooks/useUserEmailFromParam.ts";
import utils from "./utils";

const getToken = () => window.localStorage.getItem("token");

const urlParams = new URLSearchParams(window.location.search);
const tokenQuery = urlParams.get("token");
const fromApp = urlParams.get("fromApp");
const studentDiscountCode = urlParams.get("sdc");
const debug = urlParams.get("debug") || localStorage.getItem("debug");

const token = getToken();
if (fromApp) {
  window.localStorage.setItem("fromApp", fromApp);
}
if (studentDiscountCode === "1") {
  window.localStorage.setItem("showCouponInput", "true");
}
if (!token && tokenQuery) {
  window.localStorage.setItem("token", utils.decodeToken(tokenQuery));
  // and redirect to login
  window.location.href = "/login";
}

// test parameters for playwright tests
const testFlags = urlParams.get("testFlags");
const testAnswers = urlParams.get("testAnswers");
const testSwimlane = urlParams.get("testSwimlane");

// allows for email to be set for user from URL
// requires userEmail=(url encoded email) as well to work
const isWinbackFromApp = urlParams.get("wbfa") === "1";

if (isWinbackFromApp) {
  window.localStorage.setItem("isWinbackFromApp", "true");
}

if (testFlags) {
  window.localStorage.setItem("testFlags", testFlags);
}
if (testAnswers) {
  window.localStorage.setItem("testAnswers", testAnswers);
}
if (testSwimlane) {
  window.localStorage.setItem("testSwimlane", testSwimlane);
}

export default function App() {
  const { data } = useRemoteConfig();
  const { isLoading, isLoggedIn } = useUserData();

  useFirstQuizLoad();

  // store email from userEmail
  useUserEmailFromParam();

  useEffect(() => {
    localStorage.setItem("debug", debug || "");
  }, []);

  const appInitialising = data == null || (!isLoggedIn && isLoading);

  return appInitialising ? (
    <LoadingRadial />
  ) : (
    <ExperimentsProvider generalSettings={data.generalSettings}>
      <QuizStateProvider>
        <SpeedInsights />
        <RouterProvider router={AppRouter()} />
      </QuizStateProvider>
    </ExperimentsProvider>
  );
}
