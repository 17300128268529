import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import React, { Suspense } from "react";

import LoadingRadial from "../components/base/LoadingRadial";
import NavBar from "../components/NavBar";
import RequireAnon from "../RequireAnon";
import RequireAuth from "../RequireAuth";

const LoginFlagsCheck = React.lazy(
  () => import("../pages/Account/LoginFlagsCheck"),
);

const Landing = React.lazy(() => import("../pages/Landing"));
const Login = React.lazy(() => import("../pages/Login"));

const MyInflowResultsPage = React.lazy(
  () => import("../adhdQuiz/pages/MyInflowResultsPage"),
);

const LiveEventLinkPage = React.lazy(
  () => import("../adhdQuiz/pages/LiveEventLinkPage"),
);

const MyResultsPage = React.lazy(
  () => import("../adhdQuiz/pages/MyResultsPage"),
);
const CheckoutPaymentPage = React.lazy(
  () => import("../checkout/pages/CheckoutPaymentPage"),
);

const CheckoutPriceOptionsPage = React.lazy(
  () => import("../checkout/pages/CheckoutPriceOptionsPage"),
);

const AccountRouter = React.lazy(
  () => import("../pages/Account/AccountRouter"),
);

const AdhdQuizBaseRouter = React.lazy(
  () => import("../adhdQuiz/routing/AdhdQuizBaseRouter"),
);

const PostPurchase = React.lazy(() => import("../pages/PostPurchase"));

const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));

const WebViewQuizRouter = React.lazy(() => import("./WebViewQuizRouter"));

const WinbackPlainPage = React.lazy(
  () => import("../pages/Winback/WinbackPlainPage"),
);

const Winback30offPage = React.lazy(
  () => import("../pages/Winback/Winback30offPage"),
);
const RedirectHcpReferralToQuiz = React.lazy(
  () => import("../pages/RedirectHcpReferralToQuiz"),
);

export function AppRouter() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route
        path={"/"}
        element={
          <Suspense fallback={<LoadingRadial />}>
            <Outlet />
          </Suspense>
        }
      >
        <Route
          path={"/"}
          element={
            <Suspense fallback={<LoadingRadial />}>
              <Landing />
            </Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <Login />
            </Suspense>
          }
        />

        <Route
          path="/winback-plain"
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <WinbackPlainPage />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path="/winback-30off"
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <Winback30offPage />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path="/winback-checkout/payment/:priceId"
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <CheckoutPaymentPage />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path="/hcp-referral"
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <RedirectHcpReferralToQuiz />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path="/checkout/price-options"
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <CheckoutPriceOptionsPage />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path="/checkout/payment/:priceId"
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <CheckoutPaymentPage />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path="/checkout/payment"
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <CheckoutPaymentPage />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path="/checkout/start-trial"
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <CheckoutPaymentPage />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path={"/adhd-quiz/*"}
          element={
            <Suspense fallback={<LoadingRadial />}>
              <AdhdQuizBaseRouter />
            </Suspense>
          }
        />

        <Route
          path={"/results/*"}
          element={
            <Suspense fallback={<LoadingRadial />}>
              <MyResultsPage />
            </Suspense>
          }
        />

        <Route
          path={"/live/*"}
          element={
            <Suspense fallback={<LoadingRadial />}>
              <LiveEventLinkPage />
            </Suspense>
          }
        />

        <Route
          path={"/my-inflow-results/*"}
          element={
            <Suspense fallback={<LoadingRadial />}>
              <MyInflowResultsPage />
            </Suspense>
          }
        />

        <Route
          path={"/post-purchase"}
          element={
            <Suspense fallback={<LoadingRadial />}>
              <PostPurchase />
            </Suspense>
          }
        />

        <Route
          path="/auth"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <LoginFlagsCheck />
            </Suspense>
          }
        />

        <Route
          path="/account/*"
          element={
            <RequireAuth to="/login">
              <Suspense fallback={<LoadingRadial />}>
                <NavBar />
                <AccountRouter />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="/reset-password"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          path="/web-view-quiz/*"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <WebViewQuizRouter />
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate to={"/"} replace />} />
      </Route>,
    ),
  );
}
